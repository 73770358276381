@media all and (display-mode: standalone) {
    .App-bar {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 50px;
        background-color: yellow;
    }
    .App-bar::before {
        content: 'App launched as installed application';
    }
}

.App {
    text-align: center;
}

.App-header {
    background-color: #2254b9;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}
